e4.p.icon = {
    init: function () {
        var jSVG = jQuery( '.js-p-icontext-animate svg' );
        if ( jSVG.length > 0 ) {
            e4.util.require.js( e4.settings.require.drawSVG, function () {
                jSVG.drawsvg( {
                    duration: e4.settings.animation.speed * 5,
                    stagger: 750
                } );
                e4.p.icon.animate();
                e4.p.icon.bindEvents();
            } );
        }
    },
    bindEvents: function () {
        e4.p.icon.isRAF = false;
        jQuery( window ).on( 'scroll', function () {
            if ( e4.p.icon.isRAF === false ) {
                window.requestAnimationFrame( function () {
                    e4.p.icon.animate();
                    e4.p.icon.isRAF = false;
                } );
            }
            e4.p.icon.isRAF = true;
        } );
    },
    animate: function () {
        jQuery( '.js-p-icontext-animate svg' ).each( function () {
            var jThis = jQuery( this ),
                jThisParagraph = jThis.closest( '.js-p-icontext-animate' );
            if ( e4.util.isInViewport( jThisParagraph ) === true ) {
                jThis.drawsvg( 'animate' );
                jThisParagraph.removeClass( 'js-p-icontext-animate' );
            }
        } );
    }
};

